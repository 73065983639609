import React from "react"
import { PageProps, graphql } from "gatsby"
import Helmet from "react-helmet"

import { SEO } from "src/components/seo"
import { VideoLoopResponsive } from "src/components/video-responsive"
import { ImgFluid } from "src/components/img-fluid"

import {
  HeadlineAndParagraph,
  HeaderHero,
  CaseStudyCapabilities,
} from "src/components/layouts"

import {
  Container,
  Block,
  Grid,
  Col,
  HorizontalRuler,
  Headline,
  Paragraph,
  ParagraphTall,
  HeadlineHero,
} from "src/components/primitives"

import { useDarkTheme } from "src/context/theme"

type DataProps = {
  project: {
    seo: {
      title: string
      description: string
      image: {
        publicURL: string
      }
    }
    copy: {
      title: string
      subtitle: string
      intro: string
      overview: string
      how_it_works_title: string
      how_it_works_copy: string
      contact_title: string
      contact_copy: string      
    }
    images: {
      hero_static: any
      grid_1_pivot_static: any
      grid_1_ideas_cards_static: any
      grid_1_downwrite_static: any
      grid_1_dynamicwall_prototype_static: any
      grid_1_pivot_ui_static: any
      grid_1_ideas_desktop_static: any
      grid_1_dynamicwall_static: any
      illustration_static: any
    }
    videos: {
      hero: any
      grid_1_pivot: any
      grid_1_ideas_cards: any
      grid_1_downwrite: any
      grid_1_dynamicwall_prototype: any
      grid_1_pivot_ui: any
      grid_1_ideas_desktop: any
      grid_1_dynamicwall: any
    }
  }
}

const CaseStudy: React.FC<PageProps<DataProps>> = ({ data }) => {
    const { seo, copy, images, videos } = data.project
  
    useDarkTheme()
  
    return (
      <article aria-label={copy.title}>
        <Helmet
          bodyAttributes={{
            class: "theme-dark",
          }}
        />
        <SEO
          title={seo.title}
          description={seo.description}
          image={seo.image.publicURL}
        />
        <Container>
          <HeaderHero>
            <HeadlineHero>
              {copy.title}
              <span>{copy.subtitle}</span>
            </HeadlineHero>
          </HeaderHero>
        </Container>

        <Container bleed>
        <Block>
          <VideoLoopResponsive
            video={videos.hero}
            image={images.hero_static}
          />
        </Block>
      </Container>
  
        <Container>
          <Paragraph>{copy.intro}</Paragraph>
        </Container>

        <Container>
          <Block>
            <Grid gap columns={{ bp0: "$1", bp1: "$6" }}>
              <Col
                start={{ bp0: "$1", bp1: "$1" }}
                end={{ bp0: "$1", bp1: "$7" }}
              >
                  <VideoLoopResponsive
                    ratio="$16/9"
                    video={videos.grid_1_pivot}
                    image={images.grid_1_pivot_static}
                  />
              </Col>
              <Col 
                start={{ bp0: "$1", bp1: "$1" }}
                end={{ bp0: "$1", bp1: "$3" }}
              >
                <VideoLoopResponsive
                  ratio="$1/1"
                  video={videos.grid_1_ideas_cards}
                  image={images.grid_1_ideas_cards_static}
                />
              </Col>
              <Col
                start={{ bp0: "$1", bp1: "$3" }}
                end={{ bp0: "$1", bp1: "$5" }}
              >
                <VideoLoopResponsive
                  ratio="$1/1"
                  video={videos.grid_1_downwrite}
                  image={images.grid_1_downwrite_static}
                />
              </Col>
              <Col
                start={{ bp0: "$1", bp1: "$5" }}
                end={{ bp0: "$1", bp1: "$7" }}
              >
                <VideoLoopResponsive
                  ratio="$1/1"
                  video={videos.grid_1_dynamicwall_prototype}
                  image={images.grid_1_dynamicwall_prototype_static}
                />
              </Col>
              <Col
                start={{ bp0: "$1", bp1: "$1" }}
                end={{ bp0: "$1", bp1: "$4" }}
              >
                <VideoLoopResponsive
                  ratio="$1/1"
                  video={videos.grid_1_pivot_ui}
                  image={images.grid_1_pivot_ui_static}
                />
              </Col>
              <Col
                start={{ bp0: "$1", bp1: "$4" }}
                end={{ bp0: "$1", bp1: "$7" }}
              >
                <VideoLoopResponsive
                  ratio="$1/1"
                  video={videos.grid_1_ideas_desktop}
                  image={images.grid_1_ideas_desktop_static}
                />
              </Col>
              <Col
                start={{ bp0: "$1", bp1: "$1" }}
                end={{ bp0: "$1", bp1: "$7" }}
              >
                  <VideoLoopResponsive
                    ratio="$16/9"
                    video={videos.grid_1_dynamicwall}
                    image={images.grid_1_dynamicwall_static}
                  />
              </Col>
            </Grid>
          </Block>
        </Container>

        <Container>
          <Grid gap columns={{ bp4: "$12" }}>
            <Col start={{ bp4: "$6" }} end={{ bp4: "$13" }}>
              <ParagraphTall>{copy.overview}</ParagraphTall>
            </Col>
          </Grid>
        </Container>
  
        <Container>
          <HorizontalRuler />
        </Container>

        <Container>
          <HeadlineAndParagraph>
            <Headline>{copy.how_it_works_title}</Headline>
            <Paragraph>{copy.how_it_works_copy}</Paragraph>
          </HeadlineAndParagraph>
        </Container>

        <Container>
        <Block>
          <ImgFluid image={images.illustration_static} />
        </Block>
      </Container>

        <Container>
          <HorizontalRuler />
        </Container>

        <Container>
          <HeadlineAndParagraph>
            <Headline>{copy.contact_title}</Headline>
            <Paragraph>{copy.contact_copy}</Paragraph>
          </HeadlineAndParagraph>
        </Container>
      </article>
    )
  }
  
export default CaseStudy
  
export const pageQuery = graphql`
  query {
    project: workJson(id: { eq: "lab" }) {
      id
      seo {
        title
        description
        image {
          publicURL
        }
      }
      copy {
        title
        subtitle
        intro
        overview
        how_it_works_title
        how_it_works_copy
        contact_title
        contact_copy
      }
      images {
        hero_static {
          alt
          src {
            childImageSharp {
              ...HeroImageFragment
            }
          }
        }
        grid_1_pivot_static {
          alt
          src {
            childImageSharp {
              ...FullWidthImageFragment
            }
          }
        }
        grid_1_ideas_cards_static {
          alt
          src {
            childImageSharp {
              ...HalfColumnImageFragment
            }
          }
        }
        grid_1_downwrite_static {
          alt
          src {
            childImageSharp {
              ...HalfColumnImageFragment
            }
          }
        }
        grid_1_dynamicwall_prototype_static {
          alt
          src {
            childImageSharp {
              ...HalfColumnImageFragment
            }
          }
        }
        grid_1_pivot_ui_static {
          alt
          src {
            childImageSharp {
              ...HalfColumnImageFragment
            }
          }
        }
        grid_1_ideas_desktop_static {
          alt
          src {
            childImageSharp {
              ...HalfColumnImageFragment
            }
          }
        }
        grid_1_dynamicwall_static {
          alt
          src {
            childImageSharp {
              ...FullWidthImageFragment
            }
          }
        }
        illustration_static {
          alt
          src {
            childImageSharp {
              ...FullWidthImageFragment
            }
          }
        }
      }
      videos {
        hero {
          src {
            src_large
            src_medium
            src_small
          }
        }
        grid_1_pivot {
          src {
            src_large
            src_medium
            src_small
          }
        }
        grid_1_ideas_cards {
          src
        }
        grid_1_downwrite {
          src
        } 
        grid_1_dynamicwall_prototype {
          src
        }
        grid_1_pivot_ui {
          src
        }
        grid_1_ideas_desktop {
          src
        }
        grid_1_dynamicwall {
          src {
            src_large
            src_medium
            src_small
          }
        } 
      }
    }
  }
`